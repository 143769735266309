import React from 'react';
import styled from 'styled-components';
import { FdrWishlistIcon } from 'fdr/components/candidate/fdr-wishlist-icon';
import { EWishlistOfferMode } from 'hp-mf/fdr/components/fdr-wishlist/fdr-use-wishlist-offer';
import useWishlistOfferWithLoginRecommender from './fdr-use-wishlist-offer-with-login-recommender';
import { WishlistSource } from './fdr-wishlist.constants';
import FdrWishlistLoginRecommenderModal from './login-recommender/fdr-wishlist-login-recommender-modal';

export interface IFavourableContainerProps extends React.PropsWithChildren<{}> {
  offerId: string;
  productId: string;
  inWishlist: boolean;
  source?: WishlistSource;
  withLabel?: boolean;
  withTooltip?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>, isActive: boolean): void;
  className?: string;
  activeTooltip?: string;
  inactiveTooltip?: string;
  mode?: EWishlistOfferMode;
}

const FavourableContainerWrapper = styled.div({
  position: 'relative',
});

const FdrFavourableContainer: React.FC<IFavourableContainerProps> = ({
  offerId,
  productId,
  inWishlist,
  source = null,
  withLabel,
  withTooltip,
  onClick,
  children,
  className,
  activeTooltip,
  inactiveTooltip,
  mode,
}) => {
  const {
    isActive,
    loading,
    toggle,
    isRecommenderModalShown,
    setRecommenderModalClose,
  } = useWishlistOfferWithLoginRecommender({
    offerId,
    productId,
    source,
    inWishlist,
    mode,
  });

  function clickHandler(e: React.MouseEvent<HTMLElement>) {
    toggle();

    onClick && onClick(e, isActive);
  }

  return (
    <FavourableContainerWrapper className={className}>
      {children}
      <FdrWishlistIcon
        id={productId}
        isActive={isActive}
        withLabel={withLabel}
        withTooltip={withTooltip}
        onClick={clickHandler}
        loading={loading}
        activeTooltip={activeTooltip}
        inactiveTooltip={inactiveTooltip}
      />
      <FdrWishlistLoginRecommenderModal
        onClose={setRecommenderModalClose}
        show={isRecommenderModalShown}
      />
    </FavourableContainerWrapper>
  );
};

export default FdrFavourableContainer;
