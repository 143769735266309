import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrWishlistFragmentDoc } from 'hp-mf/fdr/schemas/fragment/wishlist/fdr-wishlist.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrAddItemToActiveWishlistMutationVariables = Types.Exact<{
  input: Types.FdrAddWishlistItemToActiveInput;
}>;

export type FdrAddItemToActiveWishlistMutation = {
  __typename?: 'Mutation';
  fdrAddWishlistItemToActive: {
    __typename?: 'FdrAddWishlistItemToActiveResponse';
    success: boolean;
    message?: string | null;
    wishlist?: {
      __typename?: 'FdrWishlist';
      id: string;
      name: string;
      active: boolean;
      productsAmount: number;
      shareUrl?: string | null;
      wishlistItems?: Array<{
        __typename?: 'FdrWishlistItem';
        source?: string | null;
        product:
          | {
              __typename: 'FdrCruise';
              name: string;
              id: string;
              hero?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              summaryFeatureGroups?: Array<{
                __typename?: 'FdrProductFeatureGroup';
                id: string;
                name: string;
                features: Array<{
                  __typename?: 'FdrProductFeature';
                  id: string;
                  name: string;
                  enabled: boolean;
                }>;
              }> | null;
              destination: {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                };
              };
              hubData?: {
                __typename?: 'FdrHubProductData';
                taRating: number;
                taReviews: number;
              } | null;
              includedInPrice?: {
                __typename?: 'FdrTitleText';
                title: string;
                text: string;
              } | null;
              webMeta: {
                __typename?: 'FdrProductWebMeta';
                offersWebMeta?: {
                  __typename?: 'FdrMeta';
                  title: string;
                  description: string;
                  index: boolean;
                  noFollow: boolean;
                  sitemap: boolean;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            }
          | {
              __typename: 'FdrHotel';
              rating: number;
              mapZoom?: number | null;
              name: string;
              id: string;
              hero?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              summaryFeatureGroups?: Array<{
                __typename?: 'FdrProductFeatureGroup';
                id: string;
                name: string;
                features: Array<{
                  __typename?: 'FdrProductFeature';
                  id: string;
                  name: string;
                  enabled: boolean;
                }>;
              }> | null;
              descriptionFeatureGroups?: Array<{
                __typename?: 'FdrProductFeatureGroup';
                id: string;
                name: string;
                features: Array<{
                  __typename?: 'FdrProductFeature';
                  id: string;
                  name: string;
                  enabled: boolean;
                }>;
              }> | null;
              resort: {
                __typename?: 'FdrResort';
                name: string;
                id: string;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                  };
                };
              };
              tripAdvisorReviews?: {
                __typename?: 'FdrTripAdvisorReviewsResponse';
                totalRating?: number | null;
                numReviews?: number | null;
              } | null;
              coordinates?: {
                __typename?: 'FdrCoordinates';
                latitude: number;
                longitude: number;
              } | null;
              flags?: { __typename?: 'FdrHotelFlags'; top: boolean } | null;
              webMeta: {
                __typename?: 'FdrProductWebMeta';
                offersWebMeta?: {
                  __typename?: 'FdrMeta';
                  title: string;
                  description: string;
                  index: boolean;
                  noFollow: boolean;
                  sitemap: boolean;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            }
          | {
              __typename: 'FdrRoundTrip';
              name: string;
              id: string;
              hero?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              summaryFeatureGroups?: Array<{
                __typename?: 'FdrProductFeatureGroup';
                id: string;
                name: string;
                features: Array<{
                  __typename?: 'FdrProductFeature';
                  id: string;
                  name: string;
                  enabled: boolean;
                }>;
              }> | null;
              destination: {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                };
              };
              hubData?: {
                __typename?: 'FdrHubProductData';
                taRating: number;
                taReviews: number;
              } | null;
              includedInPrice?: {
                __typename?: 'FdrTitleText';
                title: string;
                text: string;
              } | null;
              webMeta: {
                __typename?: 'FdrProductWebMeta';
                offersWebMeta?: {
                  __typename?: 'FdrMeta';
                  title: string;
                  description: string;
                  index: boolean;
                  noFollow: boolean;
                  sitemap: boolean;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            }
          | {
              __typename: 'FdrAdventureTravel';
              name: string;
              id: string;
              webMeta: {
                __typename?: 'FdrProductWebMeta';
                offersWebMeta?: {
                  __typename?: 'FdrMeta';
                  title: string;
                  description: string;
                  index: boolean;
                  noFollow: boolean;
                  sitemap: boolean;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                } | null;
              };
            };
        trackedOffer: {
          __typename?: 'FdrTrackedProductOffer';
          status: Types.FdrProductOfferStatus;
          latest?: {
            __typename?: 'FdrProductOffer';
            id: string;
            departureDate: any;
            returnDate: any;
            duration: number;
            transferIncluded: boolean;
            travelType: Types.FdrProductTravelType;
            waitingScreenImage: string;
            provider?: string | null;
            flightAlternativesAvailable: boolean;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            priceTotal: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            rooms: Array<{
              __typename?: 'FdrProductOfferRoom';
              adults: number;
              childrenDobs?: Array<any> | null;
              mealDescription: string;
              roomDescription: string;
              bookingCode: string;
              mealType: string;
              roomType: string;
            }>;
            transferPrice?: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            } | null;
            forwardFlight?: {
              __typename?: 'FdrFlight';
              duration: number;
              overnight: boolean;
              baggageInfo?: Array<string> | null;
              segments: Array<{
                __typename?: 'FdrFlightSegment';
                flightNumber: string;
                flightClass: Types.FdrFlightClass;
                duration: number;
                arrivalDate: any;
                arrivalTime: any;
                baggageIncluded: boolean;
                baggageInfo?: Array<string> | null;
                departureDate: any;
                departureTime: any;
                airportWaiting?: number | null;
                airline: {
                  __typename?: 'FdrAirline';
                  iata: string;
                  name: string;
                };
                arrivalAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
                departureAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
              }>;
            } | null;
            returnFlight?: {
              __typename?: 'FdrFlight';
              duration: number;
              overnight: boolean;
              baggageInfo?: Array<string> | null;
              segments: Array<{
                __typename?: 'FdrFlightSegment';
                flightNumber: string;
                flightClass: Types.FdrFlightClass;
                duration: number;
                arrivalDate: any;
                arrivalTime: any;
                baggageIncluded: boolean;
                baggageInfo?: Array<string> | null;
                departureDate: any;
                departureTime: any;
                airportWaiting?: number | null;
                airline: {
                  __typename?: 'FdrAirline';
                  iata: string;
                  name: string;
                };
                arrivalAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
                departureAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
              }>;
            } | null;
          } | null;
          original: {
            __typename?: 'FdrProductOffer';
            id: string;
            departureDate: any;
            returnDate: any;
            duration: number;
            transferIncluded: boolean;
            travelType: Types.FdrProductTravelType;
            waitingScreenImage: string;
            provider?: string | null;
            flightAlternativesAvailable: boolean;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            priceTotal: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            rooms: Array<{
              __typename?: 'FdrProductOfferRoom';
              adults: number;
              childrenDobs?: Array<any> | null;
              mealDescription: string;
              roomDescription: string;
              bookingCode: string;
              mealType: string;
              roomType: string;
            }>;
            transferPrice?: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            } | null;
            forwardFlight?: {
              __typename?: 'FdrFlight';
              duration: number;
              overnight: boolean;
              baggageInfo?: Array<string> | null;
              segments: Array<{
                __typename?: 'FdrFlightSegment';
                flightNumber: string;
                flightClass: Types.FdrFlightClass;
                duration: number;
                arrivalDate: any;
                arrivalTime: any;
                baggageIncluded: boolean;
                baggageInfo?: Array<string> | null;
                departureDate: any;
                departureTime: any;
                airportWaiting?: number | null;
                airline: {
                  __typename?: 'FdrAirline';
                  iata: string;
                  name: string;
                };
                arrivalAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
                departureAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
              }>;
            } | null;
            returnFlight?: {
              __typename?: 'FdrFlight';
              duration: number;
              overnight: boolean;
              baggageInfo?: Array<string> | null;
              segments: Array<{
                __typename?: 'FdrFlightSegment';
                flightNumber: string;
                flightClass: Types.FdrFlightClass;
                duration: number;
                arrivalDate: any;
                arrivalTime: any;
                baggageIncluded: boolean;
                baggageInfo?: Array<string> | null;
                departureDate: any;
                departureTime: any;
                airportWaiting?: number | null;
                airline: {
                  __typename?: 'FdrAirline';
                  iata: string;
                  name: string;
                };
                arrivalAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
                departureAirport: {
                  __typename?: 'FdrAirport';
                  name: string;
                  isGroup: boolean;
                  iata: string;
                  codes?: Array<string> | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    longitude: number;
                    latitude: number;
                  } | null;
                };
              }>;
            } | null;
          };
        };
      }> | null;
    } | null;
  };
};

export const FdrAddItemToActiveWishlistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'FdrAddItemToActiveWishlist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrAddWishlistItemToActiveInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrAddWishlistItemToActive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wishlist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrWishlist' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrWishlistFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export type FdrAddItemToActiveWishlistMutationFn = Apollo.MutationFunction<
  FdrAddItemToActiveWishlistMutation,
  FdrAddItemToActiveWishlistMutationVariables
>;

/**
 * __useFdrAddItemToActiveWishlistMutation__
 *
 * To run a mutation, you first call `useFdrAddItemToActiveWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdrAddItemToActiveWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdrAddItemToActiveWishlistMutation, { data, loading, error }] = useFdrAddItemToActiveWishlistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrAddItemToActiveWishlistMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FdrAddItemToActiveWishlistMutation,
    FdrAddItemToActiveWishlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FdrAddItemToActiveWishlistMutation,
    FdrAddItemToActiveWishlistMutationVariables
  >(FdrAddItemToActiveWishlistDocument, options);
}
export type FdrAddItemToActiveWishlistMutationHookResult = ReturnType<
  typeof useFdrAddItemToActiveWishlistMutation
>;
export type FdrAddItemToActiveWishlistMutationResult =
  Apollo.MutationResult<FdrAddItemToActiveWishlistMutation>;
export type FdrAddItemToActiveWishlistMutationOptions =
  Apollo.BaseMutationOptions<
    FdrAddItemToActiveWishlistMutation,
    FdrAddItemToActiveWishlistMutationVariables
  >;
